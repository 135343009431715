<template>
  <div
    v-if="debugData?.app.productSubscription && debugData?.apiToken"
    class="items-center invisible mx-auto md:visible border-border-light border-1 rounded-alt-xl"
  >
    {{ props.data.currentDate }}
    <div class="flex flex-row items-center justify-start md:justify-center">
      <FormKit
        v-model="newDate"
        type="text"
        name="NEXT ORDER DATE"
        validation="date_format:DD.MM.YYYY"
        validation-visibility="blur"
        input-class="w-full input rounded-corners"
        inner-class="relative pt-[5px] w-full"
        label-class="text-md !leading-[19px] font-bold font-pero"
      />
    </div>
    <BasicButton
      classes="w-full my-sm px-0 font-pero text-lg"
      label="Senden"
      bg-color="#555555"
      :type="EButtonType.SUBMIT"
      :btn-style="EButtonStyle.CTA"
      @click="updateNextOrderDate(newDate)"
    />
  </div>
</template>
<script setup lang="ts">
import { FormKit } from '@formkit/vue';
import { EButtonStyle, EButtonType } from '@/@types/basic-button';
import { useSecureSessionPost } from '~/composables/dataFetching/genericFetchers';
import { BasicButton } from '@/complib';
import { handleLoadingError } from '~/utils/handleLoadingError';
import useGetDebugData from '../useGetDebugData';

const props = defineProps({
  data: {
    type: Object as any,
    required: false,
    default: null,
  },
});

const newDate = ref('');
const debugData = useGetDebugData();

async function updateNextOrderDate(date: string) {
  try {
    await useSecureSessionPost<string>(
      `/api/${useSiteIdent()}/admin/${debugData.value.apiToken}/debug/shop/productSubscription/nextOrderDate/update`,
      {
        subscriptionId: props.data.subscriptionId,
        nextOrderDate: date,
      },
    );
  } catch (e) {
    handleLoadingError(e);
  }
}
</script>
